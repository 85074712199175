


































































































































import Vue, { PropType } from "vue";
import type { State } from "@/store";
import getPermissionLevels from "@/helpers/permissions/getPermissionLevels";
import {
  USER_PERMISSION_NONE,
  USER_PERMISSION_NORMAL,
  USER_PERMISSION_ADMIN,
  USER_PERMISSION_SUPER,
  USER_PERMISSION_CAN_SEE_RECRUITS_INFO,
  USER_PERMISSION_CAN_GIVE_BONSUSES
} from "@/permissions";
import { firestore, callableFunctions } from "@/firebase";

import ActionButton from "./ActionButton.vue";
import AllotmentDescription from "./AllotmentDescription.vue";
import Checkbox from "./Checkbox.vue";
import ErrorNotice from "./ErrorNotice.vue";
import LoadingIndicator from "./LoadingIndicator.vue";
import Modal from "./Modal.vue";
import Radio from "./Radio.vue";
import UserName from "./UserName.vue";

export default Vue.extend({
  name: "UserChangePermissionsModal",
  components: {
    ActionButton,
    AllotmentDescription,
    Checkbox,
    ErrorNotice,
    LoadingIndicator,
    Modal,
    Radio,
    UserName
  },
  props: {
    userId: { type: String, required: true },
    accountId: { type: String, required: true },
    open: { type: Boolean, required: true },
    closeModal: { type: Function as PropType<() => void>, required: true }
  },
  data: () => ({
    level: null as AccountPermissions.Level | null,
    canGiveBonuses: false,
    canSeeRecruitsInfo: false,
    updatingPermissions: false,
    error: null as Error | null,
    permissions: null as (AccountPermissions.Permission & AccountPermissions.Access) | null,
    permissionWatcher: null as Watcher | null
  }),
  computed: {
    account(): AccountStoreEntry | undefined {
      return this.$store.direct.state.accounts.all[this.accountId];
    },
    superAdministratorsLimit(): number {
      return this.account?.limits?.superusers ?? 0;
    },
    administratorsLimit(): number {
      return this.account?.limits?.administrators ?? 0;
    },
    referringUsersLimit(): number {
      return this.account?.limits?.referringUsers ?? 0;
    },
    superAdministratorsCount(): number {
      return this.account?.counts?.registeredUsers?.super ?? 0;
    },
    administratorsCount(): number {
      return this.account?.counts?.registeredUsers?.admin ?? 0;
    },
    referringUsersCount(): number {
      return this.account?.counts?.registeredUsers?.normal ?? 0;
    },
    canAddMoreSuperusers(): boolean {
      return this.superAdministratorsLimit - this.superAdministratorsCount > 0;
    },
    canAddMoreAdministrators(): boolean {
      return this.administratorsLimit - this.administratorsCount > 0;
    },
    canAddMoreReferringUsers(): boolean {
      return this.referringUsersLimit - this.referringUsersCount > 0;
    },
    state(): State {
      return this.$store.direct.state;
    },
    accountName(): string {
      return this.account?.name ?? "";
    },
    USER_PERMISSION_NONE: () => USER_PERMISSION_NONE,
    USER_PERMISSION_NORMAL: () => USER_PERMISSION_NORMAL,
    USER_PERMISSION_ADMIN: () => USER_PERMISSION_ADMIN,
    USER_PERMISSION_SUPER: () => USER_PERMISSION_SUPER,
    USER_PERMISSION_CAN_SEE_RECRUITS_INFO: () => USER_PERMISSION_CAN_SEE_RECRUITS_INFO,
    USER_PERMISSION_CAN_GIVE_BONSUSES: () => USER_PERMISSION_CAN_GIVE_BONSUSES
  },
  watch: {
    level() {
      this.canGiveBonuses = false;
      this.canSeeRecruitsInfo = false;
    },
    open(open) {
      if (open) {
        this.watchPermissions();
      } else {
        this.unwatchPermissionsForUser();
      }
    },
    permissions: {
      immediate: true,
      handler(permissions: (AccountPermissions.Permission & AccountPermissions.Access) | null) {
        this.level = permissions?.level ?? null;
        this.canGiveBonuses = permissions?.canGiveBonuses ?? false;
        this.canSeeRecruitsInfo = permissions?.canSeeRecruitsInfo ?? false;
      }
    }
  },
  methods: {
    getFirestoreUserPermissionDocumentRef(): DocumentReference {
      return firestore
        .collection("accounts")
        .doc(this.accountId)
        .collection("userPermissions")
        .doc(this.userId);
    },
    watchPermissions(): void {
      this.permissionWatcher = this.getFirestoreUserPermissionDocumentRef().onSnapshot(
        newUserPermissionsSnapshot => {
          if (newUserPermissionsSnapshot.exists) {
            const perms = newUserPermissionsSnapshot.data();
            this.permissions = {
              ...perms,
              ...getPermissionLevels(perms as AccountPermissions.AgnosticPermission)
            } as AccountPermissions.Permission & AccountPermissions.Access;
          }
        }
      );
    },
    unwatchPermissionsForUser(): void {
      if (this.permissionWatcher) {
        this.permissionWatcher();
      }
      this.permissionWatcher = null;
    },
    async saveAndClose(): Promise<void> {
      if (this.updatingPermissions) {
        return;
      }
      this.updatingPermissions = true;
      this.error = null;
      const special = [];
      if (this.level === this.USER_PERMISSION_NORMAL && this.canSeeRecruitsInfo) {
        special.push(this.USER_PERMISSION_CAN_SEE_RECRUITS_INFO);
      }
      if (this.level === this.USER_PERMISSION_ADMIN && this.canGiveBonuses) {
        special.push(this.USER_PERMISSION_CAN_GIVE_BONSUSES);
      }
      console.log("new Permissions", this.level, special);
      try {
        await callableFunctions.changeUserAccessLevel({
          accountId: this.accountId,
          userId: this.userId,
          level: this.level ?? USER_PERMISSION_NONE,
          special
        });
        this.closeModal();
      } catch (error) {
        if (error instanceof Error) {
          this.error = error;
        } else {
          this.error = new Error(error);
        }
      }
      this.updatingPermissions = false;
    }
  }
});
