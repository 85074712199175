import isAdmin from "./isAdmin";
import isAccountUser from "./isAccountUser";
import isNormal from "./isNormal";
import isSuperAdmin from "./isSuperAdmin";
import hasLimitedPermissions from "./hasLimitedPermissions";
import canGiveBonuses from "./canGiveBonuses";
import canSeeRecruitsInfo from "./canSeeRecruitsInfo";

/**
 * Reduces a user permissions document to distinct booleans.
 * @param providedUserPermissions The user permissions data
 * @returns The user's account permissions.
 */
export default function getPermissionLevels(
  providedUserPermissions: AccountPermissions.AgnosticPermission
): AccountPermissions.Access {
  const userPermissions: AccountPermissions.AgnosticPermission = {
    level: providedUserPermissions?.level ?? "",
    special: providedUserPermissions?.special ?? []
  };

  return {
    isAdmin: isAdmin(userPermissions),
    isAccountUser: isAccountUser(userPermissions),
    isNormal: isNormal(userPermissions),
    isSuperAdmin: isSuperAdmin(userPermissions),
    hasLimitedPermissions: hasLimitedPermissions(userPermissions),
    canGiveBonuses: canGiveBonuses(userPermissions),
    canSeeRecruitsInfo: canSeeRecruitsInfo(userPermissions)
  };
}
