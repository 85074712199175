import { USER_PERMISSION } from "./USER_PERMISSION";

export default function isAdmin(userPermissions: AccountPermissions.AgnosticPermission): boolean {
  if (
    userPermissions.level === USER_PERMISSION.SUPER ||
    userPermissions.level === USER_PERMISSION.ADMIN
  ) {
    return true;
  }
  return false;
}
