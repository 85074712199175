import { USER_PERMISSION } from "./USER_PERMISSION";

export default function isAccountUser(
  userPermissions: AccountPermissions.AgnosticPermission
): boolean {
  if (
    userPermissions.level === USER_PERMISSION.NORMAL ||
    userPermissions.level === USER_PERMISSION.ADMIN ||
    userPermissions.level === USER_PERMISSION.SUPER
  ) {
    return true;
  }
  return false;
}
