import { USER_PERMISSION } from "./USER_PERMISSION";

export default function canGiveBonuses(
  userPermissions: AccountPermissions.AgnosticPermission
): boolean {
  const canGiveBonuses =
    Array.isArray(userPermissions.special) &&
    userPermissions.special.some(sp => sp === "canGiveBonuses");
  if (
    userPermissions.level === USER_PERMISSION.SUPER ||
    (userPermissions.level === USER_PERMISSION.ADMIN && canGiveBonuses)
  ) {
    return true;
  }
  return false;
}
