
















import Vue, { PropOptions } from "vue";

export default Vue.extend({
  name: "AllotmentDescription",
  props: {
    count: { type: Number, required: true },
    max: {
      required: true,
      validator: prop => typeof prop === "number" || prop === null
    } as PropOptions<number | null>
  }
});
