


























import Vue, { PropType } from "vue";
import UserChangePermissionsModal from "./UserChangePermissionsModal.vue";

export default Vue.extend({
  name: "AccountUser",
  components: {
    UserChangePermissionsModal
  },
  props: {
    user: { type: Object as PropType<Account.User>, required: true }
  },
  data: () => ({
    permissionModalOpen: false
  }),
  computed: {
    accountId(): string | undefined {
      return this.$route.params.accountId;
    }
  },
  methods: {
    openChangePermissionModal(): void {
      console.log("openChangePermissionModal");
      this.permissionModalOpen = true;
    },
    closeChangePermissionModal(): void {
      this.permissionModalOpen = false;
    }
  }
});
