











import Vue from "vue";
import orderBy from "lodash/orderBy";

import ListFirestore from "@/components/ListFirestore.vue";
import AccountUser from "@/components/AccountUser.vue";
import { db } from "@/firebase";

export default Vue.extend({
  name: "AccountUsers",
  components: {
    AccountUser,
    ListFirestore
  },
  computed: {
    accountId(): string | undefined {
      return this.$route.params.accountId;
    },
    account(): AccountStoreEntry | undefined {
      return this.accountId ? this.$store.direct.state.accounts.all[this.accountId] : undefined;
    }
  },
  methods: {
    getAccountUsers() {
      return db
        .collection("accounts")
        .doc(this.accountId)
        .collection("users")
        .where("registered", "==", true)
        .orderBy("firstName", "asc");
    },
    orderList(items: InvitationCode[]) {
      return orderBy(Object.values(items), "remainingUses", "desc");
    }
  }
});
