import { USER_PERMISSION } from "./USER_PERMISSION";

export default function canSeeRecruitsInfo(
  userPermissions: AccountPermissions.AgnosticPermission
): boolean {
  const canSeeRecruitsInfo =
    Array.isArray(userPermissions.special) &&
    userPermissions.special.some(sp => sp === "canSeeRecruitsInfo");
  if (userPermissions.level === USER_PERMISSION.NORMAL && canSeeRecruitsInfo) {
    return true;
  }
  return false;
}
