import { USER_PERMISSION } from "./USER_PERMISSION";

export default function hasLimitedPermissions(
  userPermissions: AccountPermissions.AgnosticPermission
): boolean {
  if (userPermissions.level === USER_PERMISSION.LIMITED) {
    return true;
  }
  return false;
}
