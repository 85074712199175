import { USER_PERMISSION } from "./USER_PERMISSION";

export default function isSuperAdmin(
  userPermissions: AccountPermissions.AgnosticPermission
): boolean {
  if (userPermissions.level === USER_PERMISSION.SUPER) {
    return true;
  }
  return false;
}
